.sideBarContainer {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafb;
  font-family: "Nunito", sans-serif;
}
.sidebar_mainHeader {
  margin-bottom: -5rem;
  margin-top: 25px;
  text-align: center;
}
.upgrade_now {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-bottom: 2rem;
  align-items: center;
  
}
/* .activeTab{
  background: linear-gradient(to right, #ACA9FF, #ACA9FF00);
  color: #605BFF;
  width: 53px;
height: 48px;
border-radius: 0px 5px 5px 0px;

} */
.upgrade_now_image{
  margin-bottom: -3rem;
}

.upgrade_now_button{
  width: 138px;
height: 33px;
flex-shrink: 0;
border-radius: 10px;
background: var(--Primary, #605BFF);
border: none;
font-size: 12px;
color: white;
}
.sideBar {
  height: 100% !important;
  width: 100%;
}
.sidebar_tripprLogo{
  border-radius: 10px;
  width: 153px;
  height: 52px;
background: #605BFF;
}
.flex-column {
  gap: 23px;
  margin-bottom: 0;
  padding-left: 12px;
  margin-top: 8rem;
  margin-bottom: 5rem;
}
.flex-column p {
  color: #686868;
  width: 70%;
}
#main_row {
  gap: 0.8%;
}
#colSidebar {
  width: 14%;
  border-radius: 8px;
  height: 97vh;
  background-color: white;
  font-family: "Nunito", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
}
#colRightBar {
  width: 85%;
  border-radius: 8px;
  background-color: #fafafb;
  padding-top: 16px;
}
/* .colSidebar {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
} */
.colRightBar {
  background-color: #fff;
  position: relative;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
}
.nav-link {
  color: #686868;
  font-style: normal;

  font-weight: 600;
  font-size: 16px;
  display: flex;
  gap: 8px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.nav-link:focus,
.nav-link:hover,
.nav-link:active {
  color: #605BFF;
}

.nav-link img {
  width: 16px;
  height: 16px;
}
