.CandidateDetailsSidebarDiv {
    border-radius: 10px;
    background: #F2F2F2;
    width: 306px;
    height: auto;
    /* flex-shrink: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
}
img.candidateProfilePicture {
    border-radius: 8rem;
    /* width: 147px; */
}
.portfolioLinks {
    display: flex;
    gap: 6px;
}

p.skillsAndLanuguageHeadingsP {
    color: var(--pri-text, #243360);
    font-size: 16px;
}
.skillsAndLanuguageHeadingsPSecond {
    font-size: 14px;
    text-transform: capitalize;
    background: var(--gradient-prim, linear-gradient(180deg, #F72E30 0%, #C73568 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: none;
}
span.skillsAndLanuguageHeadings {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 13px;
    margin-bottom: -10px;
}
.candidatePortfoliosInputs {
    border-radius: 5px;
    border: 1px solid #DADADA;
    background: #FFF;
    width: 275px;
    height: 100%;
    padding: 1rem
}
.skillAddRemoveButton {
    margin-left: 9px;
    margin-bottom: 10px;
}
input.candidatePortfoliosInputSecond {
    border: none;
    padding-left: 1rem;
}
.candidatePortfoliosInputs{
    font-size: 14px;
}
p.portfolionHeading {
    color: var(--pri-text, #243360);
    font-size: 16px;
    margin-left: -5rem;
    margin-top: 1rem;

}
.JobSearchDashboardHeader {
    background: var(--gradient-prim, linear-gradient(180deg, #F72E30 0%, #C73568 100%));
    width: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    gap: 6px;
    height: 100%;
}
.JobSearchDashboardMain {
    margin-top: -33px;
}
#JobSearchDashboardUser {
    /* width: 42px; */
    /* height: 42px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 46px #00000094;
    /* display: flex; */
    text-decoration: none;
    color: black;
    padding: 5px;
    border-radius: 8px;
}