.clientlistSearchBoxFirstP {
    text-align: left;
    text-decoration: underline;
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #1745D0;
}
.ClientListSearchBoxTwo {
    display: flex;
    padding-top: 2rem;
    gap: 10px;
    justify-content: space-between;
    align-items: baseline;
    padding: 2rem 4rem 0 4rem;
}
.magnifyingGlassP {
    width: 240px;
    height: 40px;
    background: #495281 0% 0% no-repeat padding-box;
    border-radius: 110px;
    text-align: center;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.totalJobsHeadingsDivLast {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 23px #00000030;
    border: 1px solid #929EBE;
    position: absolute;
    margin-top: 2rem;
    margin-left: -2rem;
    padding: 7px 12px;
}
.totalJobsHeadingsDivLastHeadings {
    font: normal normal 14px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    text-transform: uppercase;
}
.getCandidateDetailsById {
    pointer-events: all; 
    width: 950px;
    /* height: 789px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    position: fixed;
    top: 0;
    right: 0;
    max-height: 100%;
    overflow: auto;
    padding: 10px 10px 10px 16px;
}
.getCandidateDetailsNameP {
    color: #243360;
    font-weight: 700;
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.getCandidateDetailsDivP1 {
    font: normal normal 16px/21px Open Sans;
    letter-spacing: 0px;
    color: #485280;
    border: 1px solid #485280;
    border-radius: 6px;
    width: 91px;
    height: 36px;
    padding-top: 6px;
    text-align: center;
}
.getCandidateDetailsDivP2 {
    font: normal normal 16px/21px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 141px;
    height: 36px;
    background: var(--unnamed-color-495281) 0% 0% no-repeat padding-box;
    background: #495281 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding-top: 6px;
    text-align: center;
}
.getCandidateDetailsDivP3 {
    color: #485280;
}
.getCandidateDetailsFilterButtons {
    font: normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #8D95B0;
    text-transform: capitalize;
    margin-bottom: 0;
    cursor: pointer;
}
.getCandidateDetailsFilterBtnDiv {
    display: flex;
    gap: 15px;
    background: aliceblue;
    align-items: baseline;
    padding-top: 9px;
    padding-bottom: 9px;
    justify-content: space-around;
}
.activegetCandidateDetailsFilterButtons{
background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    border-radius: 3px;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 6px;
}
.getCandidateDetailsFilter {
    display: flex;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 14px;
    gap: 1rem;
}
p.candidateProfilePictureDivP2 {
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
p.candidateProfilePictureDivP1 {
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.getCandidateNecessityDivOne {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001A;
    border: 1px solid #929EBE61;
    border-radius: 6px;
    padding: 1rem;
}
.getCandidateNecessityDivTwo {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001A;
    border: 1px solid #929EBE61;
    border-radius: 6px;
    padding: 1rem;
    width: 66%;

}
.getCandidateNecessityDiv {
    display: flex;
    gap: 13px;
}
.getCandidateNecessityDivFilterBtn {
    display: flex;
    gap: 20px;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
p.getCandidateNecessityDivskills {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    text-align: center;
    padding: 5px;
}
p.getCandidateNecessityDivskillsFirst {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.getCandidateNecessityDivskillsLastDiv {
    background: #FFF9EE 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FFA80079;
    border: 1px solid #FFA800;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
span.getCandidateNecessityDivskillsLastspan {
    display: flex;
    gap: 2rem;
}
p.getCandidateNecessityDivskillsLastspanP {
    font: normal normal 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
p.getCandidateNecessityDivskillsLastspanP2 {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    margin-bottom: 0;

}
p.getCandidateNecessityExperienceP1 {
    font: normal normal 600 16px/22px Open Sans;
    margin-bottom: 0;

    letter-spacing: 0px;
    color: #243360;
}
.getCandidateNecessityExperience {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-bottom: 1px dashed #b9adad;
    padding-bottom: 1rem;
}
img.companyProfilePictureImg {
    width: 69px;
    height: 62px;
}
.getCandidateNecessityDivDetailsSkills {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    gap: 10px;
}
.ChevronRightDiv {
    color: black;
    background: #e4dddd;
}
.getCandidateDetailsName {
    display: flex;
}
.getCandidateDetailsNameDiv {
    display: flex;
    gap: 11px;
}
.getCandidateDetailsName {
    display: flex;
    justify-content: space-between;
}
.getCandidateDetailsByIdMain {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}
.getCandidateNecessityDivFilterBtnExperience {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
}
.MessageToCandidate {
    padding: 9px;
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #929EBE 0% 0% no-repeat padding-box;
    border-radius: 6px;
}
.MessageToCandidateDiv {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
}
p.getCandidateNecessityExperienceP2 {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    margin-bottom: 0;
}
.activeCandidateFilter{
    border-bottom: 4px solid #d35b5d;
    border-radius: 4px;
    padding-bottom: 4px;

}
.getCandiDateDetailsFirstDivFilter {
    height: 100vh;
}
.candiDateListTableThead {
    width: 100%;
}
.candiDateListFirstTR ,.candiDatetListSecondTR{
    width: initial !important;
}
.candiDateListTableTbody {
    width: 100%;
}
.noFeedbackisHere{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
img.feedBackImage {
    width: 198px;
    height: auto;
}
.feedBackImagePara{
color: black;
margin-top: 1rem;
font-weight: 500;
}
.getNoFeedBackIshere{
display: flex;
    justify-content: center;
    margin: 130px auto;
}
.timelineOfCandiDateDivP1 {
    font: normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
p.timelineOfCandiDateDivP2 {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
.timelineOfCandiDateDiv {
    margin-top: 1rem;
    border-bottom: 2px dashed #ccc7c7;
}
p.emailOfCandidateP1 {
    font: normal normal 16px/21px Open Sans;
    letter-spacing: 0px;
    color: #485280;
}
p.emailOfCandidateP2 {
    border: 1px solid #D33359;
    border-radius: 6px;
    color: #f72e30;
    padding: 5px;
}
.emailOfCandidateFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
.emailOfCandidateFlexDiv {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: 1px solid #C2CBE3;
    border-radius: 6px;
    margin-top: 11px;
}
span.emailOfCandidateFlexSpan {
    background: #FEECEC 0% 0% no-repeat padding-box;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 0px 14px;
    align-items: center;
    padding-top: 9px;
}
.emailOfCandidateFlexSpanP {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.emailOfCandidateFlexSpanP2 {
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
.emailOfCandidateFlexDivPSub {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    padding: 0px 13px;
    padding-top: 12px;
    margin-bottom: 7px;
}
.emailOfCandidateFlexDivDescription {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    padding: 0px 13px;
    padding-top: 5px;
    margin-bottom: 13px;
}
.activeFeedbackButtonsAll {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    padding: 7px;
    border-radius: 20px;
}
p.feedbackButtonAll {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #C5C9D9;
    border: 1px solid #C5C9D9;
    border-radius: 20px;
    padding: 6px 12px;
}
.feedBackButtons {
    display: flex;
    gap: 8px;
    margin-top: 1rem;
}
.feedbackButtonDivMain {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: 1px solid #D5DCEF;
    border-radius: 10px;
    box-shadow: 0px 3px 10px #0000001A;
    border: 1px solid #D5DCEF;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 13px 16px;
}
img.candidateProfilePictureFeedback {
    width: 46px;
    height: 46px;
    border-radius: 30px;
}
p.designationNameFirst {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    margin-bottom: 0;
}
p.designationName {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
p.feedbackButtonDivMainP {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
.feedbackButtonDivMainFlexDiv {
    display: flex;
    justify-content: space-between;
}
p.candidateProfilePictureFeedbackP1 {
    background: #FFFAEB 0% 0% no-repeat padding-box;
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #C69E33;
    padding: 9px;
    border-radius: 10px;
}
.candidateProfilePictureFeedbackP2 {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
.feedbackThumbs{
    color:#0B913A;
    font-size: 28px;
}
.feedbackDesP {
    display: flex;
    justify-content: space-between;
}