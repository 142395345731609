.ql-toolbar {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
  }
  
  .ql-container {
    border: 1px solid #ccc;
    border-radius: 4px;  
    background-color: #fff;
    height: 10rem !important;
  }
  /* .ql-editor h3{
color: black;
  } */
  .ql-editor h4 {
    margin-left: 20px; /* Adjust the left margin to create space */
    position: relative;
  }
  
  .ql-editor h4::before {
    content: '•'; /* Unicode for bullet point */
    color: black; /* Bullet color */
    display: inline-block;
    width: 1em; /* Set the width of the bullet */
    margin-left: -1em; /* Position the bullet to the left of the text */
    position: absolute;
    text-align: center;
  }
  .totalJobFilterBox {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: 1px solid #49528121;
    padding: 10px;
    margin: 13px 23px 0 22px;
    position: absolute;
    width: 96%;
}
.buttonsOfAddJob {
    display: flex;
    justify-content: space-between;
    align-items: end;
}
p.buttonsAddPrequestion {
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    /* padding-left: 0rem; */
    cursor: pointer;
    /* width: 232px; */
    /* height: 40px; */
    border: 1px solid #DC324F;
    border-radius: 6px;
    padding: 8px;
    margin-left: 1rem;    color: #F72E30;
    font-weight: 600;
}
.buttonsCancAndNextAddJob {
    display: flex;
    gap: 1rem;
}
.totalJobFilterBoxFilterMain {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 8px;
}
.totalJobFilterBoxDivTwo {
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.filterBoxSubmit {
    display: flex;
    justify-content: center;
    align-items: unset;
    position: relative;
    top: 1rem;
    gap: 10px;
}
.totalJobboxPartStart {
    padding: 0 4rem;
}

.totalJobsHeadingsFilter {
    font: normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #8C94AF;
    text-transform: uppercase;
    cursor: pointer;
}
.totalJobsfilterButtons {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #8C94AF;
    margin-bottom: 2rem;
}
.totalJobsHeadings {
    display: flex;
    gap: 1rem;
}
.ClientListSearchSpanP {
    box-shadow: -10px -10px 34px #FFFFFF;
    border: 1px solid #D93253;
    border-radius: 6px;
    color: #F72E30;
    width: 84px;
    height: 40px;
    text-align: center;
    /* padding-top: 6px; */
    cursor:pointer;
}
span.totalJobsJobBoxFirstSpan {
    display: flex;
    align-items: baseline;
    gap: 16px;
    color: #8C94AF;
}
p.totalJobTime {
    /* color: #8C94AF; */
    width: 90px;
    height: 28px;
    background: #FFF3F3 0% 0% no-repeat padding-box;
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #FF0706;
    text-align: center;
}
.totalJobsJobBoxStart {
    background: white;
    box-shadow: 0px 0px 14px 0px rgba(240,96,96,1);
    padding: 11px;
}
.totaljobTitle {
    font: normal normal normal 16px/22px Open Sans;
    color: #40496a;
}
.totalJobsJobBoxFirstHeadings {
    display: flex;
    justify-content:space-between
}
.totalJobsApplicants {
    display: flex;
}
span.totalJobId {
    display: flex;
    color: #8C94AF;
    font: normal normal 16px/19px Open Sans;
    gap: 1.3rem;
}p.totalJobIdNo {
    display: flex;
    gap: 3px;
}
.JobIdptagCopy{
    color: black;
    font-weight: 700;
}
.totalJobAboutTheJob {
    gap: 19px;
    display: grid;
    grid-template-columns: auto auto;
    color: #8C94AF;
    font: normal normal 16px/19px Open Sans;
}
span.totalJobtheJobLocTime {
    display: flex;
    gap: 6px;
}
p.assignedPara {
    color: #FF0706;
}
.totalJobsApplicants {
    display: flex;
    background: antiquewhite;
    border-radius: 10px;
    justify-content: space-around;
    text-align: center;
    padding-top: 6px;
}
p.totalJobsApplicantsDetailsP1 {
    color: #8C94AF;
    font: normal normal 16px/19px Open Sans; 
}
p.totalJobsApplicantsDetailsP2 {
    background: #fab3b9;
    border-radius: 8px;
    color: #584a4a;
    font-weight: 700;
    padding: 3px 9px 3px 9px
}
span.totalJobsApplicantsDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}
span.totalJobsJobBoxFirstSpan {
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.totalJobGridBox {
    display: grid;
    gap: 35px;
    grid-template-columns: auto auto auto;
}
.totalJobCardSelectedMainDiv {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}
.totalJobCardSelected {
    width: 455px;
    height: 49px;
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
}
.ViewJObById {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px 0px 0px 6px;
}
.ellipsisLinksOfJobList {
    width: 184px;
    height: 244px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 23px #00000030;
    border: 1px solid #929EBE;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 10px;
    position: absolute;
    margin-left: -9rem;
    margin-top: 1rem;
}

.ellipsisLinksOfJobListSecond{
    width: 207px;
    height: 163px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 23px #00000030;
    border: 1px solid #929EBE;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 10px;
    margin-left: 1rem;
    margin-top: 9rem;
    display: none;
}
.ellipsisLinksOfJobListTag {
    color: var(--unnamed-color-243360);
    text-align: left;
    font: normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    text-decoration: none;
}
.ellipsisLinksOfJobList{
    display: none;
}
.ellipsisLinksOfJobListSecond{
    display: none;

}
.dotDot:hover::after .ellipsisLinksOfJobList{
    display: flex;
    position: relative;
}
.totaljobViewByIdHeadings {
    display: flex;
    justify-content: space-between;
}
.totalJobCompanyPictures {
    display: flex;
    margin-bottom: 1rem;
    padding: 9px;
    gap: 10px;
}
.totalJobCompanyPictures1 {
    width: 20%;
    height: auto;
    border: 1px solid #707070;
border-radius: 6px;
}
.totalJobCompanyPictures2 {
    width: 80%;
    height: auto;
    border: 1px solid #707070;
border-radius: 6px;
}
.totalJobJobDetailsById {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #AAAAAA;
    border-radius: 6px;
    padding: 15px;
}
.totaljobViewById {
    padding: 2rem;
}
.totaljobViewByIdFirstP {
    color: var(--unnamed-color-243360);
    /* text-align: left; */
    font: normal normal bold 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.totalJobDetailsbyIdSpanTitle {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    text-transform: capitalize;
    margin-bottom: 5px;
    margin-top: 10px;
}
.totalJobDetailsbyIdInput {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    font: normal normal medium 14px/19px Open Sans;
letter-spacing: 0px;
color: #243360;
text-align: center;
}
p.totalJobJobDetailsByIdTwoP {
    color: var(--unnamed-color-243360);
    text-align: left;
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    margin-bottom:1.3rem;
    text-transform: capitalize;
}
.totalJobViewDetails {
    display: flex;
    gap: 15px;
}
span.totalJobDetailsIdInputFlex {
    display: grid;
    /* grid-template-columns: auto auto auto; */
}
p.totalJobJobDetailsByIdTwoP2 {
    color: var(--unnamed-color-495281);
    text-align: left;
    font: normal normal 14px/20px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
textarea.totalJobJobDetailsByIdTextArea {
    color: var(--unnamed-color-495281);
    text-align: left;
    font: normal normal 14px/20px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    border: none;
    width: 100%;
}
.totalJobJobDetailsByIdTextArea:focus{
    /* outline: none; */
    /* border: none; */
}
.totalJobTextAreaPart {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}
.totaljobAboutCompany {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    text-transform: capitalize;
    margin-top: 1rem;
}
.totaljobAboutCompanyDesc {
    color: var(--unnamed-color-495281);
    text-align: left;
    font: normal normal 14px/20px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
.prescreeningquestionDiv {
    position: fixed;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 0;
    transform: translateX(100%);
    width: 0;
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    z-index: 999; /* Make sure it's on top of other content */
    transition: opacity 0.5s, transform 0.5s, width 0.5s;
}
.prescreeningquestionDivShow{
    opacity: 1;
    transform: translateX(0);
    width: 24rem;
    padding: 1rem;
    transition: opacity 0.5s;
    pointer-events: all;
}
.faCloseMark{
    color: #707070;
}
span.spanOfPrescreeningQuestions {
    display: flex;
    justify-content: space-between;
}
.addquestionbuttonPrescreen{
    color: #F72E30;
    width: initial !important;
    background: transparent;
    border: none;
    font: normal normal 14px/20px Open Sans;
    margin-top: 5px;
}
.prescreeningquestionDivShadow {
    width: 100%;
    height: 100%;
    position: fixed;
    /* opacity: 0.4; */
    top: 0;
    left: 0;
    pointer-events: none;
    background: rgb(100 100 100 / 70%);
}
p.prescreeningquestionHeading {
    color: var(--unnamed-color-243360);
    /* text-align: center; */
    font: normal normal bold 20px/21px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}

.prescreeningquestionDivShadowFlex {
    display: flex;
    flex-direction: column;
    gap: 17rem;
    justify-content: space-evenly;
}
.mandatoryStar {
    cursor: pointer;
    margin-left: 5px;
    color: #ccc;
    font-size: 20px;
    position: absolute;
    margin-left: -4rem;
    margin-top: 3px;
  }
  
  .mandatoryStar.active {
    color: rgb(248 170 13);/* Change to your desired color for active/mandatory skills */
  }
  .onBoardPrimaryDetailsSkillAdding {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 27px 25px 11px 10px;
    gap: 20px;
    margin-bottom: 1rem;
}
.skillInputContainer {
    padding-top: 10px;
    /* background: #F7F7F7 0% 0% no-repeat padding-box; */
    /* border: 1px solid #FFFFFF; */
    /* border-radius: 30px; */
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 6px;
    margin-top: -25px;
}
.addSkillButtonforSkillsPartDiv {
    display: flex;
    position: absolute;
    right: 7px;
    bottom: -12px;
}
.onBoardPrimaryDetailsInputDataSkill {
    border: none;
    /* background: transparent; */
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    width: 7.4rem;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    padding: 9px 7px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}
  .onBoardPrimaryDetailsInputDataSkill:focus{
    outline: none;
    border: none;
  }
  .addSkillButtonforSkillsPart {
    font: normal normal 14px/22px Open Sans;
    color: #D1335D;
    background: #FFEBEB 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding: 4px 12px;
    margin-top: 1rem;
    margin-left: 10px;
}
.trashDelete {
    color: #243360;
    cursor: pointer;
}
span.prescreeningQuestionSpan {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 5px;
}
.predefinedQuestions {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 5px;
   
}
.dotQuestionAns {
    letter-spacing: 0px;
    color: #243360 !important;
    font-size: 12px
}
.onBoardPrimaryDetailsLabelDivQues {
    display: flex;
    /* align-items: self-end; */
    flex-direction: column;
}
.onBoardPrimaryDetailsLabelDivQuesDiv2{
display: flex;
align-items: baseline;
gap: 7px;}

.onBoardPrimaryDetailsInputDataTextarea {
    border: none !important;
    color: black;
    width: 100% !important;
    height: initial;
}
.onBoardPrimaryDetailsInputDataTextarea:focus{
    outline: none;
    border: none;
}
.predefinedQuestion {
    /* margin-top: -1rem; */
}
.crossMarkDelete {
    color: #a8a3a3;
    margin-left: -2rem;
}
.jobdescriptioneditorMainDiv {
    background: #929EBE 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    display: flex;
    padding: 8px;
    gap: 7px;
}
.prescreeningquestionmapingDiv {
    padding: 21px 12px 16px 13px;
}
.prescreeningquestioneditorSelect {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #8C94AF;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    height: 28px;
}
.prescreeningquestioneditorSelectDiv {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #8C94AF;
    gap: 19px;
    height: 27px;
    justify-content: space-between;
    padding: 0px 11px;
}
.editablecontent {
    text-decoration: underline;
  }

  .prescreningquestionButtonsEdit {
    background: transparent;
    border: none;
    width: initial;
    color: #8C94AF;
}
.onboardPrimaryMinMaxDiv {
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

}
.onBoardPrimaryDetailsInputData2 {
    width: 11.3rem;
}
.clientAddJobSkillsDivInput {
    display: flex;
}
.pMinuesP {
    color: black;
    margin-bottom: 0;
    margin-top: 5px;
    padding: 0px 5px;
}