.JarvixJobDashboardMaiDiv {
    background: #202430;
    font-family: 'Epilogue', sans-serif;
    padding: 0 4rem 0 4rem;
}
/* index.js */
h1.JarvixJobDashboardHeadingOne {
    color: #FFF;
    font-feature-settings: 'cv11' on;
    font-size: 66px;
    font-weight: 600;
    width: 29rem;
}
p.greatplatformP {
    color: #FFF;
    font-size: 20px;
    width: 521px;
}
.JarvixJobDashboardHeadingTwo {
    color: var(--accents-blue, #26A4FF);
}
.JarvixJobDashboardNavbarLogin1 {
    color: var(--brands-primary, #4640DE);
    font-size: 16px;
    text-decoration: none;
    font-weight: 700;
}
a.NavbarLinksTwo {

color: var(--neutrals-10-i, #F8F8FD);
font-size: 16px;
text-decoration: none;
}
a.JarvixJobDashboardNavbarLogin2 {
    color: var(--neutrals-0, #FFF);
    font-size: 16px;
    font-weight: 700;
    padding: 11px 17px;
    background: var(--brands-primary, #4640DE);
    text-decoration: none;
}
.JarvixJobDashboardNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 3rem;
}
.JarvixJobDashboardNavbarLinks {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.SearchJobByLocation {
    background: var(--color-white, #FFF);
    box-shadow: 0px 2.71357px 4.39666px 0px rgba(192, 192, 192, 0.03), 0px 6.86281px 11.11949px 0px rgba(192, 192, 192, 0.04), 0px 13.99949px 22.68272px 0px rgba(192, 192, 192, 0.05), 0px 28.8363px 46.72211px 0px rgba(192, 192, 192, 0.06), 0px 79px 128px 0px rgba(192, 192, 192, 0.09);
    display: flex;
    width: 670px;
    padding: 14px;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    gap: 14px;
}
.jobSearchButton {
    color: var(--neutrals-0, #FFF);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    background: var(--brands-primary, #4640DE);
    display: flex;
    width: 209px;
    padding: 14px 27px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
}
input.searchJobsByInput {
    border: none;
    padding-left: 10px;
    border-bottom: 1px solid #D6DDEB;
}
select.jobSearchSelectLocation {
    border: none;
}
.JarvixJobDashboardNavbarLoginPart {
    display: flex;
    align-items: center;
    gap: 13px;
}

/* exlpore by category */

.ExploreByCategory {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 27px;
}
.ExploreByCategoryTwo {
    border: 1px solid var(--neutrals-20, #D6DDEB);
    background: var(--neutrals-0, #FFF);
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
}
.ExploreByCategoryType {
    color: var(--neutrals-100, #25324B);
    font-size: 24px;
    margin: 0;
}
.explorebyCategoryLinkA {
    color: var(--neutrals-60, #7C8493);
    font-size: 18px;
    text-decoration: none;
}
.blueBackgroundImgDiv {
    width: 100%;

}
img.blueBackgroundImg {
    width: 100%;
    height: auto;
    position: relative;
}
.ctaPart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.ctaPartDiv {
    position: absolute;
    z-index: 1;
    left: 8rem;
}
p.ctaPartDivP2 {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}
.ctaPartDivP1 {
    color: #FFF;
    font-feature-settings: 'cv11' on;
    font-size: 48px;
    font-weight: 600;
    width: 364px;
}
a.signUpForFreeLink {
    color: var(--brands-primary, #4640DE);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 24px;
    background: var(--neutrals-0, #FFF);
    text-decoration: none;
}
/* latest Jobs open */
.LatestJobsOpenH4 {
    color: var(--neutrals-10-i, #F8F8FD);
    font-feature-settings: 'cv11' on;
    font-size: 48px;
    font-weight: 600;
}
.LatestJobsOpensLists {
    display: grid;
    gap: 13px;
    grid-template-columns: auto auto;
}
.latestJobsTitle {
    color: var(--neutrals-100, #25324B);
    font-family: Epilogue;
    font-size: 20px;
    font-weight: 600;

}
.latestJobsTitleLocation {
    color: var(--neutrals-80, #515B6F);
    font-size: 16px;
    font-weight: 400;
}
.latestJobsAboutProfile {
    display: flex;
    gap: 10px;
}
p#borderLeftProfile {
    padding: 6px 10px;
    border-radius: 80px;
    background: rgba(86, 205, 173, 0.10);
    color: var(--accents-green, #56CDAD);
    font-size: 14px;
    font-weight: 600;
}
p#latestJobsAboutProfile2 {

color: var(--accents-yellow, #FFB836);
font-size: 14px;
font-weight: 600;
border-radius: 80px;
border: 1px solid var(--accents-yellow, #FFB836);
padding: 6px 10px;
}

p#latestJobsAboutProfile3 {
    color: var(--brands-primary, #4640DE);
font-size: 14px;
font-weight: 600;
padding: 6px 10px;
border-radius: 80px;
border: 1px solid var(--brands-primary, #4640DE);
}


.LatestJobsOpensMainList {
    display: flex;
    align-items: flex-start;
    background: var(--neutrals-0, #FFF);
    display: flex;
    padding: 24px 40px;
    align-items: flex-start;
    gap: 24px;
    /* align-self: stretch; */
}
a.showAllJobsLink {
    color: var(--brands-primary, #4640DE);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}
.showAllJobsLink:hover{
    color: var(--brands-primary, #4640DE);
}
.LatestJobsOpenheadings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.LatestJobsOpen {
    margin-top: 4rem;
    margin-bottom: 3rem;
}
span.LatestJobsOpenH4Span {
    color: var(--accents-blue, #26A4FF);
    font-feature-settings: 'cv11' on;
}
/* featuredjobs */
.FeaturedJobsMainDiv {
    display: grid;
    gap: 1rem;
    grid-template-columns: auto auto auto auto;
}
.FeaturedJobsMainDivList {
    border: 1px solid #D6DDEB;
    background: var(--color-white, #FFF);
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    flex: 1 0 0;
}
p.FeaturedJobsTitle {
    color: var(--neutrals-100, #25324B);
    font-size: 18px;
    font-weight: 600;
}
p.FeaturedJobsLocation {
    color: var(--neutrals-80, #515B6F);
    font-size: 16px;
}
p.FeaturedJobsDescription {
    color: var(--neutrals-60, #7C8493);
    font-size: 16px;
}
p.FeaturedJobsjobTime {
    border: 1px solid var(--brands-primary, #4640DE);
    padding: 4px 12px;
    color: var(--brands-primary, #4640DE);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}
.FeaturedJobsListSpan {
    display: flex;
    align-items: center;
    gap: 6rem;
}
p#FeaturedJobsQualify {
    color: var(--accents-yellow, #FFB836);
    font-size: 14px;
    font-weight: 600;
    padding: 4px 16px;
    border-radius: 80px;
    background: rgba(235, 133, 51, 0.10);
}
p#FeaturedJobsQualify1 {
    padding: 4px 16px;
    border-radius: 80px;
    background: rgba(86, 205, 173, 0.10);
    color: var(--accents-green, #56CDAD);
    font-size: 14px;
    font-weight: 600;
}
.FeaturedJobsQualify {
    display: flex;
    gap: 10px;
}
/* footer css */
.jobFooterMainDiv {
    display: flex;
    justify-content: space-between;
}
.jobFooter {
    padding-top: 3rem;
    padding-bottom: 2rem;
}
.p.jobFooterFlexheadings {

color: var(--neutrals-0, #FFF);
font-size: 18px;
font-weight: 600;
}
.jobFooterFlexheadingsLink ,.jobFooterflexP,.jobFooterDeadline {

color: var(--neutrals-20, #D6DDEB);
font-size: 16px;
font-weight: 400;
text-decoration: none;
}
.jobFooterFormInput{
border: 1px solid var(--neutrals-20, #D6DDEB);
background: var(--neutrals-0, #FFF);display: flex;
width: 223px;
padding: 12px 16px;
align-items: center;
gap: 10px;}
.jobFooterMainDivFlex {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.jobFooterFlexheadingsLink:hover{
    color: #D6DDEB;
}
button.jobFooterFormButton {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--brands-primary, #4640DE);
    width: auto !important;
    color: #FFF;
}
form.jobFooterForm {
    display: flex;
    gap: 8px;
}
.jobFooterFormLastDiv {
    display: flex;
    justify-content: space-between;
}
p.jobFooterflexP {
    width: 24rem;
    margin-top: 0.8rem;
}
a.jobFooterFlexheadingsLink {
    padding-bottom: 10px;
}
span.jobFooterSocial {
    display: flex;
    gap: 13px;
}
hr.jobFooterFormHr {
    /* width: 90%; */
    margin: auto;
    padding-top: 2rem;
    /* padding-bottom: 1rem; */
    margin-top: 1rem;
}