.login-container {
  display: flex;
  /* height: 100vh; */
  background-color: #fff;
  width: 100%;
}

.miiddle-container {
  padding: 48px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 24px;
  flex: 1;
}

.miiddle-container h1 {
  color: #030229;
  margin-bottom: 24px;
}
.tripprLogo{
  background: #605BFF;
  border-radius: 10px;
  padding: 8px;
  height: 3rem;
  margin-top: 4rem;
}
.auth_input_div {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 0.6rem;
}

.auth_input_div label {
  color: #000000;
  margin-left: 5px;
}

.auth_image_container {
  padding: 36px;
  flex: 1;
}

.auth_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.title {
  font-weight: 400;
  font-size: 25px;
  line-height: 80px;
  color: #fff;
  font-weight: 600;
  margin: 0px !important;
}
.eyeHide{
  color: black;
  margin-left: -1rem;
  opacity: 0.8;
  position: relative;
  left: -1rem;
}
.eyeShow{
  color: black;
  margin-left: -2.7rem;
  opacity: 0.5;
  width: 2.8rem;
}
.sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #444;
  margin-bottom: 24px;
  margin-top: 24px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-check-label {
  color: #444 !important;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
  justify-content: center;
  margin: 18px 0;
}

.forgot-password {
  font-weight: 500;
  font-size: 14px;
  color: #ffc107;
  margin: 0px 0px 0px 70px !important;
  cursor: pointer;
}

.btn-container {
  margin-top: 20px;
}

.last-container {
  color: #fff;
}

.join-trippr {
  color: #ffc107;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.join-trippr:hover {
  color: #ffc107;
}

.signup-title {
  margin-bottom: 15px !important;
}
#signupForm {
 display: flex;
    flex-direction: column;
    align-items: center;

}
.signUpFormCheckboxAgreement{
  display: flex;
  margin-top: 18px;
}
.signUpFormLabel{
  width: 16rem;
}
#Login-text {
  margin-top: 15px;
}
#Login-text a {
  text-decoration: none;
  color: #030229;
  font-family: "Raleway";
}

.login-main {
  width: 100%;
  height: 100vh;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.login-box {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #fdd329;
  padding: 15px;
  border-radius: 20px;
}

.logo {
  display: flex;
  align-items: flex-end;
}

h3 {
  color: #ffffff;
  padding: 0px 0px;
  font-size: 28px;

  font-weight: 900;
  margin: 2px 24px;
}

.login-title {
  color: #fff;
  margin-top: 12px;
  font-size: 16px;
  margin-left: 25px;
}

.login-input-area {
  width: 60%;
}

.login-input-area input {
  background: #686927;
  border: #686927;
  color: #ffffff;
  margin: 0px !important;
}

placeholder {
  color: #ffffff !important;
}

label {
  color: #ffffff;
}

.information {
  color: #ffd04d !important;
}

.information p {
  margin: 0px !important;
  font-size: 13px;
}

.btn-default {
  background: #fdd329;
  width: 26%;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

.btn:hover {
  background: #0056fb;
  color: #fff;
}

.btn a :hover {
  color: #000000 !important;
}

.btn-default a {
  color: #000000;
  text-decoration: none;
}

.addressSignup {
  color: #e7e5e5;
  text-align: start;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .login-box {
    width: 90% !important;
  }

  .login-input-area {
    width: 90% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .login-box {
    width: 60% !important;
  }

  .login-input-area {
    width: 80% !important;
  }
}

.verify-textbox {
  /* display: flex; */
  /* justify-content: space-between; */
  margin-top: 10px;
}

.custom-otp {
  display: flex;
  justify-content: space-between;
}

/* .verify-textbox input {
    border-radius: 0px !important;
    width: 7%;
    background-color: #686927;
    text-align: center;
    font-size: 18px;
} */

/* input::placeholder {
    color: #ffffff !important;
    font-size: 13px;
}



header {
    margin-top: 70px !important;
}

input[type=number]:focus {
    border: 1px solid #ffffff !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */
