.footer_wrapper {
  background-color: #0f0f0f;
  font-family: "Montserrat";
}
.footer_container {
  color: #fff;
  width: 100%;
  height: 275px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  padding-top: 3%;
  padding-right: 3%;
}
.logo_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer_container div {
  display: flex;
  flex-direction: column;
  /* align-items: ; */
  justify-content: center;
  gap: 28px;
  align-self: flex-start;
}

.footer_container p {
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat";
}

.footer_container ul {
  list-style-type: none;
  font-family: "Montserrat",sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 0 !important;
}
.footer_container ul a{
  text-decoration: none;
  color: white;
}
.footer_container ul li {
  cursor: pointer;
  font-size: 14px;
  padding-left: 0 !important;
}
.operator_div a {
  text-decoration: none;
  color: #fff;
}
.icons {
  flex-direction: row !important;
  font-size: 42px;
  justify-content: start !important;
}

.icons i {
  cursor: pointer;
}

.footer_copyright {
  text-align: center;
  border-top: 1px solid #fff;
  width: 80%;
  color: #fff;
  font-family: "Montserrat";
  margin: 0 auto;
  padding-bottom: 16px;
}
.mobile_footer_container {
  display: none;
}
.footerGooglePlayStoreImage{
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 320px) and (max-width: 620px) {
  .company_div,
  .contact_div,
  .host_div {
    display: none !important;
  }

  .footer_container {
    grid-template-columns: 1fr;
    height: 100%;
    padding: 16px;
    display: none;
  }

  .footer_container p {
    font-size: 18px;
    margin-bottom: 0;
  }
  .mobile_footer_container {
    display: block;
    color: #fff;
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .mobile_footer_container ul li {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 8px;
    list-style-type: none;
  }
  .mobile_footer_logo {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .mobile_footer_logo img {
    padding-left: 0 !important;
  }
  .social_div {
    text-align: center;
    margin-bottom: 36px;
  }
}
