.ClientListSearchSpan {
    width: 554px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #0D275014;
    border-radius: 6px;
    display: flex;
    color: #929EBE;
    align-items: center;
    padding: 10px;
    gap: 10px;
}
.clientListHeader {
    background: #F7F7F7 0% 0% no-repeat padding-box;
}
.ClientListSearchBox {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    gap: 10px;

}
.ClientListSearchSpanInput {
    border: none;
}
.ClientListSearchSpanInput:focus{
    outline: none;
}
.ClientListSearchSpanInput::-webkit-search-cancel-button ,.ClientListSearchSpanInput::-webkit-search-clear-button{
    display: none;
}
.FaFilter {
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    box-shadow: -10px -10px 34px #FFFFFF;
    border-radius: 6px;
    /* opacity: 1; */
    width: 38px;
    height: 42px;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: white;
    transform: rotate(-90deg);
}
.ClientListSearchSpanLink{
    width: 103px;
    height: 40px;
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    box-shadow: -10px -10px 34px #FFFFFF;
    border-radius: 6px;
    /* opacity: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
}

.ClientListMainDivTable {
    color: black;
    /* margin: 0 auto; */
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}
p.clientListTD {
    font: normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #243360;

    width: 100%;
}
p.ClientListTableTH {
    width: 100%;
    font: normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.clientListSecondTR {
    display: flex;
    border: 1px solid #b3b5b9;
    background: white;
    width: 1237px;
    align-items: center;
    /* text-align: center; */
    padding-top: 10px;
    margin-bottom: 2px;
    /* border: 1px solid #929EBE; */
    padding: 15px 0 1px 6px;
}
.clientListFirstTR {
    display: flex;
    padding: 10px;
    width: 1237px;
height: 55px;
align-items: center;
/* UI Properties */
background: #E3E3E3 0% 0% no-repeat padding-box;
border-radius: 10px 10px 0px 0px;
}
.clientEditITag{
    margin-left: 2rem;
    color: #243360;
}
.clientListIdcompanyName {
    color: #273BD3 !important;
}
p#clientListTDAttr {
    display: flex;
    margin-bottom: 0;
}
.faSolidIconSort{
color:#929EBE;
font-size: 11px;
    padding-left: 3px;
}