nav {
  display: flex;
  align-items: center;

  background-color: #fafafb;

  height: 60px;
  width: 100%;
}

#navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

#navbar li {
  list-style: none;
  padding: 0 18px;
  position: relative;
  color: #000000;
  font-size: 16px;
}

#navbar li a {
  text-decoration: none;
  color: #ffffff !important;
}

#navbar li a.active::after {
  color: #ffc107 !important;
}

#navbar li a:hover {
  color: #ffc107 !important;
  cursor: pointer;
}

#mobile {
  display: none !important;
}

#mobile i {
  color: #ffc107 !important;
  align-items: center;
}

.menu-title {
  color: #000;
  margin: 0px !important;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0px !important;
  padding: 5px;
  margin-top: 0;
  border-radius: 5px;
  text-decoration: none;
}

.contact-button {
  padding: 8px 24px;
  gap: 10px;
  background: #ffc107;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: none;
  color: #000000;
}

.user-info {
  background: #605bff;
  width: 30px;
  color: #fff;
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding-top: 5px;
  padding-top: 5px;
}

.logo-container {
  align-self: flex-start;
}

.logo-container img {
  width: 170px;
}
.sidebar_user_details {
  color: #000;
}
.sidebar_user_details p {
  margin-bottom: 0;
}
.sidebar_profile_section {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.sidebar_profile_section p:nth-child(2) {
  font-size: 10px;
  text-transform: capitalize;
}

.sidebar_profile_section p:nth-child(1) {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
}

.sidebar_profile_section ul {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .logo-container img {
    width: 140px;
  }
  #navbar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 140px;
    height: auto;
    background-color: #8c8b8b;
    padding: 30px 0 0 5px;
    transition: 0.4s;
  }

  #navbar.active {
    right: 0px;
    z-index: 1;
    transition: 0.6s;
    border-radius: 16px;
  }

  #mobile {
    display: block !important;
  }

  #navbar li {
    margin-bottom: 20px;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }

  .contact-button {
    white-space: nowrap;
    padding: 7px 7px !important;
  }

  .user-info {
    margin-left: 21px;
  }
}
