.CandidateRegistorMainDiv{font-family: 'Open Sans', sans-serif;}
.SignUpMainDivForm {
    position: absolute;
    right: 7rem;
    top:2rem;
}
.LoginMainDivForm{
    position: absolute;
    right: 7rem;
    top: 9rem;
}

img.signUpPageImage {
    width: 100%;
    height: auto;
}
.signRedirectingA{
    color: red;
    text-decoration: none;
}
.signRedirectingA:hover{
    color: red;
}
p.signUpPara {
    font-size: 16px;
}
h1.signUph1 {
    font-size: 36px;
}.FormInputsDiv {
    display: flex;
    flex-direction: column;
    width: 328px;
    gap: 6px;
}
.SignUpMainDivFormInputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13px;

}
.passwordRecomdation{
    color: #8C94AF;
    font-size: 13px;
}
button.signUpformSubmit {
    height: 41px;
    width: 328px;
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: white;
    border: none;
}
.SignUpMainDiv {
    /* display: flex; */
    justify-content: end;
    align-items: center;
}
input.FormInputsText {
    height: 39px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 5px;
}
.FormInputsText::placeholder{
    color: #8C94AF;
    font-size: 14px;
    padding-left: 1rem;

}
.hrTagofSignup{  
width: 5rem;
height: 3px;
background: white;
}
.lastLineHrTag{
    display: flex;
    align-items: center;
    gap: 10px;
}

input.FormInputsText:focus{
    border: none;
    outline: none;
}
.emailVerificationStep {
    position: absolute;
    /* top: -13rem; */
    display: none;
    top: 14rem;
    right: 7rem;
}
.emailVerifyPara{
    width: 20rem;
}
.emailVerificationStepDiv {
    display: flex;
    flex-direction: column;
}
.emailVerifyDiv {
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.emailVerifyForm {
    display: flex;
    flex-direction: column;
}
.verifyInputsText {
    width: 328px;
    height: 39px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 5px;
    opacity: 1;
}
.verifyInputsText::placeholder{
    color: #8C94AF;
    font-size: 14px;
    padding-left: 1rem;

}
.verifyInputsText:focus{
    border: none;
    outline: none;
}