.modalfooter {
  display: flex !important;
}

.contanier {
  text-align: center;
}

.footerbutton {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.custum {
  margin-left: 9px;
  background-color: #0056fb;
  font-family: "Montserrat";
  color: #fff;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 0;
}

.modaltitle p {
  font-size: 24px !important;
  margin: 0px !important;
}

.ReactModal__Content {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 15px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 40% !important;
  color: black;
}

.modalbody p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-family: "Montserrat";
}
