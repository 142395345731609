.candidateDetailsFillMainDiv {
    display: flex;
    padding-top: 2rem;
    font-family: "Open Sans",sans-serif;
    gap: 2rem;
}
label.toggledFormLabel {
    color: var(--pri-text, #243360);
    font-size: 16px;
}
p.candidateFormToggleHeading {
    color: var(--sec-text, #495281);
    font-size: 18px;
    font-weight: 600;
    margin-top: 2rem;
}
.candidateFormMainDiv {
    margin-top: -2rem;
}
.toggledFormInput {
    border-radius: 6px;
    border: 1px solid #8C94AF;
    background: #FFF;
    width: 245px;
    height: 39px;
    flex-shrink: 0;
    color: #8C94AF;
    font-size: 15px;
}
.toggledFormInput::placeholder{
    font-size: 14px;
    padding-left: 10px;
}
.toggledFormLabel::after{
    content: "*";
    color: red;
}
.polygonToggle{
    transform: rotate(150deg);
}
.toggledFormInputDate {
    width: 118px;
    height: 39px;
    border-radius: 6px;
    border: 1px solid #8C94AF;
    background: #FFF;
    color: #8C94AF;
    font-size: 15px;

}
.toggledFormInputSelect {
    width: 100%;
    height: 39px;
    border-radius: 6px;
    border: 1px solid #8C94AF;
    background: #FFF;
    color: #8C94AF;
    font-size: 15px;
}
.toggledFormLabelInput {
    display: flex;
    flex-direction: column;
}
.confirmationInput {
    width: 340.497px;
    height: 81.673px;
    border-radius: 6px;
    border: 1px solid #8C94AF;
    background: #FFF;
    text-align: center;
}
input:focus ,select:focus{
    outline: none;
}
.chooseFileDrag {
   
    color: #111;
text-align: center;
font-size: 14px;
}
.fileUploadClassname {
    /* display: contents; */
    width: 327.728px;
    height: 81px;
    background: #e57d90;
    border: dashed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chooseFileP {
    color: #DD314D;
    text-decoration: underline;
}
.fileUploadClassnameDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.candidateFormToggleHeadingP {
    color: var(--pri-text, #243360);
    font-size: 18px;
    margin-left: 1.4rem;
    margin-bottom: 0rem;

}

.toggledFormMainDiv {
    border-radius: 6px;
    border: 1px solid rgba(146, 158, 190, 0.50);
    background: #FFF;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.10);
}
span.candidateFormToggleSpan {
    display: flex;
    align-items: baseline;
    gap: 1rem;
    margin-left: -2rem;
}
.candidateFormToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.toggledFormDivFirst {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: auto auto auto;
    gap: 14px;
        padding: 21px;
}
.toggledFormDivLast {
    padding: 21px;
    display:flex;
    flex-direction: column;
    gap: 12px;
}
.lastDivForConfirmation {
    color: black;
    display: flex;
    align-items: center;
}.toggledFormDivThird{
display: flex;
flex-direction: column ;
}
p.addWorkExperienceP {
font-size: 16px;
background: var(--gradient-prim, linear-gradient(180deg, #F72E30 0%, #C73568 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.whatsappP{
    color: var(--sec-text, #495281);
font-family: Open Sans;
font-size: 12px;
}
input.toggledFormInputExperience {
    height: 38px;
    border-radius: 6px;
    border: 1px solid #8C94AF;
    background: #FFF;

}
.checkboxSaveNumber {
    display: flex;
    align-items: baseline;
    margin-bottom: -1.5rem;
}
/* docs upload css */

.candidateDocumentsDiv {
    width: 572px;
    /* height: 1143px; */
    border-radius: 6px;
    border: 1px solid rgba(146, 158, 190, 0.50);
    background: #FFF;
    box-shadow: -10px 7px 10px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    transition: display 0.5s ease-in;
}
.candidateDocumentsDivShow{
display: block;
transition: display 0.5s ease-in;

}
p.docsUploadMDivDetailsFirstP {
    color: var(--pri-text, #243360);
    font-size: 16px;
    margin-left: 10px;
}
.docsUploadDiffTypesPara {
    color: var(--sec-text, #495281);
    font-size: 16px;
}
.docsUploadPs {
    color: var(--pri-text, #243360);
    font-size: 16px;
}
p.docsLoginP {
    color: var(--pri-text, #243360);
    font-size: 14px;

}
h1.headingsofDocuments {
    color: var(--pri-text, #243360);
    font-size: 18px;
   
}
.docsUploadMainDivPara {
    color: var(--pri-text, #243360);
    font-size: 16px;
    margin-left: 10px;
    
}
.documentsUploadDiffTypes {
    border-radius: 6px;
    background: #F2F2F2;
    margin: 10px;
    padding: 10px 10px 10px 10px;
}
.docsUploadHeadings {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-left: 10px;
}
.documentsUploadDiffTypesSecond {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
span.documentsUploadDiffTypesSpan {
    display: flex;
    align-items: baseline;
}
.documentsUploadDiffTypesButton {
    color: #079012;
    font-size: 14px;
    width: 80.603px;
    height: 30px;
    border-radius: 6px;
    border: 1px dashed #D93153;
    background: #FFF3F3;
    margin-right: 8px;
    text-align: center;

}

.profileUploadbutton{
    width: auto !important;
    background: #dc3364;
    border: none;
    padding: 7px;
    border-radius: 8px;
    color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.headingsofDocuments {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 10px 10px;
}
img.handledropImage {
    border-radius: 4rem;
    width: 8rem;
    height: 8rem;
    position: absolute;
}
.lableForProrgressBarDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.addlinkForSocial{
    margin-left: -11rem;
    margin-top: 1rem;

}
.skillItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CircularProgressbar {
    width: 11rem !important;
    vertical-align: middle;
}
.skillsAndLanuguageMainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.socialMediaSocialLinks {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
label#fileUploadLastDocs {
    background: #FFEBEB;
    width: 515px;
    height: 130px;
    flex-shrink: 0;
    border: 1px dashed #D93153;
    display: flex;
    justify-content: center;
}
span.chooseFilePDocs {
    color: #DD314D;
    font-size: 14px;
    text-decoration-line: underline;
}span.chooseFileDragDocs {
    color: #111;
    font-size: 14px;
}
.docsUploadClassnameDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
span.docsHrTagSpan {
    color: var(--sec-text, #495281);
    font-size: 16px;
}
.candidateDocumentsDivFormMai {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.docsLoginLinks {
    display: flex;
    gap: 1rem;
}
.candidateAddDetailsSubmitDiv {
    display: flex;
    justify-content: end;
}
button.candidateAddDetailsSubmit {
    width: auto;
    background: #dc3364;
    border: none;
    padding: 7px;
    border-radius: 8px;
    color: white;
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.uploadResumeDiv {
    border-radius: 6px;
    background: #FFF;
    width: 570px;
    height: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 12rem;
    left: 28rem;
    padding: 2rem;
}
p.uploadREsumeHeadingFirst {
    color: var(--pri-text, #243360);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
p.uploadREsumeHeadingSecond {
    color: var(--sec-text, #495281);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
p.uploadREsumeButtonsFirst {
    color: var(--sec-text, #495281);
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
p.uploadREsumeButtonsSecond {
    /* width: 115.724px; */
    /* height: 36px; */
    flex-shrink: 0;
    background: linear-gradient(180deg, #F72E30 0%, #C73568 100%);
    text-align: center;
    padding: 10px;
    border-radius: 10px;
}
.uploadResumebuttons {
    display: flex;
    align-items: baseline;
    justify-content: end;
}
.uploadMainResume {
    background: rgba(0, 0, 0, 0.16);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.uploadResumeHide{
    display: none;
}
form.candidateFormDivForm {
    display: flex;
    gap: 4rem;
}