.input-style {
  background-color: #eeeef3;
  width: 280px;
  height: 45px;
  border-radius: 8px;
  border: 0px;
  outline: none;
  color: #030229;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.input-style::placeholder {
  color: #444;
  font-weight: 400;
  font-size: 14px;
}
.input-style:focus {
  outline: none !important;
}
.app-button {
  width: 300px;
  height: 45px;
  background: #605bff;
  border-radius: 8px;
  border: 0px;
  color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.textarea-style {
  background-color: #686927;
  width: 300px;
  border-radius: 4px;
  border: 0px;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  align-self: center;
  margin: 5px;
}
.textarea-style::placeholder {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.textarea-style:focus {
  outline: none !important;
  border-color: #ffc107;
  box-shadow: 0 0 5px #fdd329;
}
