.dateContainer input {
  color: #fff;
  background: rgb(88 88 88);
  border-radius: 10px;
  text-align: center;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  outline: none;
}

.date-picker-shedule:focus {
  border: none;
}

.dateContainer {
  display: flex;
}

.dateContainer img {
  width: 35px;
}

.shiftsAdd h2 {
  color: black;
}

.create_schedule_button {
  background-color: #605bff;
  border: none;
  outline: none;
  padding: 8px 16px;
  display: inline-block;
  width: 20%;
  color: #fff;
  border-radius: 6px;
  margin-top: 16px;
}

.create_schedule_container {
  width: 20%;
}

button {
  width: 4vw;
}

.firstline {
  font-style: normal;
  font-weight: 700;
  font-size: 17.9443px;
  margin: 0;
}

#content_number {
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  text-align: left;
}

/* #content_number:hover {
  border-radius: 10px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  background: #fcd9c7;
} */

#content_shift {
  font-weight: 500;
  font-size: 14.506px;
  line-height: 29px;
  margin-top: 2rem;
  color: blue;
}

.employename {
  display: flex;
  justify-content: space-around;
}

.employename button {
  width: 7vw;
  border: 1px solid red;
  border-radius: 20px;
}

p span {
  margin-left: 1rem;
  border: 1px solid red;
}

button.employeelist {
  width: 17.7rem;
  border: 1px solid black;
  border-radius: 6px;
}

.employezone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employezone button {
  width: 2vw;
  border: 1px solid black;
  border-radius: 30px;
  background: blanchedalmond;
}

.content_para button {
  border: 0;
}

.employezone {
  background: aquamarine;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  padding: 2px;
  z-index: 1;
  border-radius: 5px;
  width: 17.9rem;
  height: 40px;
  border-top: none;
  position: relative;
}

.employezone p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
}

.clickhere {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.upper-lower {
  display: flex;
  flex-direction: row;
}

.dropbtn {
  background-color: #605bff;
  color: white;
  font-weight: 600;
  font-size: 12px;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 1.5rem;
  border-radius: 6px;
  padding: 4px 8px;
  margin-top: 4px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #d0ddf6;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
}

.dropdown-content p {
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  display: block;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.left {
  width: 24px;
}

.left span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #00a559;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.employedetails {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 17rem;
  border-bottom: 0.3px solid #fff;
  height: 70px;
  justify-content: space-between;
}

.slots {
  /* margin: 20px 0 20px 0; */
}

.slots button {
  width: 20px;
  height: 24px;
}

#shift {
  background-color: #605bff;
  border-radius: 5px;
  width: 100%;
  color: #fff;
  padding: 2px 4px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  border: none;
  outline: none;
  margin-top: 2px;
}

.shiftsAdd {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.btn_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

#submit_btn {
  width: fit-content;
  padding: 8px;
  border-radius: 10px;
  background-color: #605bff;
  font-weight: 600;
  line-height: 20px;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  width: 90%;
}
#delte_btn {
  width: 10%;
  background: transparent;
  border: none;
  padding: 0;
}

#minusBtn {
  font-size: 16px;
  width: 27px;
  border-radius: 26px;
}

.schedule_container {
  display: flex;
  gap: 16px;
}

.schedule_routes {
  width: 80%;
}

.upper {
  width: 100%;
}

.lower {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editableDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 12px;
}

.editableDiv label {
  color: #e3540d;
}

.editableContent {
  display: flex;
  align-items: center;
  gap: 16px;
}

.editableContent {
  position: relative;
}

.editableContent span {
  display: inline-block;
  transition: 0.3s;
}

.editableContent input {
  transition: 0.3s;
  border: none;
  width: 100%;
  background: #e9e7e7;
  border-radius: 8px;
  padding: 4px 8px;
  outline: 0;
}

.schedule_box_wrapper {
  display: none;
}

.schedule_address:hover .schedule_box_wrapper {
  display: block;
  width: 80%;
  z-index: 99;
  height: 75px;
}

.schedule_box_wrapper {
  position: absolute;
  display: none;
  right: 0;
}

.details_mainContainer input {
  transition: 0.3s;
  border: none;
  background: transparent;
}
