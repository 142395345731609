h1.onBoardClientHeaderH1 {
    text-align: left;
    font: normal normal bold 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
/* index.js css */
/*  */
.onBoardClientHeaderMain {
    background: #F7F7F7 0% 0% no-repeat padding-box;
}
.onBoardClientHeader {
    background: white;
    margin: 2rem;
    padding: 2rem;
}
.onBoardClientProgressBar {
    height: 75px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 2.4rem;
}
/* s */
.progressBarP {
    position: relative;
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
  }
  .stepHeadingsMain {
    font: normal normal medium 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #8C94AF;
    margin-top: 5px;
}
  .progressBarP  .tickMarkStep  {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    line-height: 20px;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .progressBarP.activeStep {
    background-color: #72D05F;
    transition: background-color 0.3s ease;
    height: 3px;
  }
  
  .progressBarP.activeStep .tickMarkStep {
    background-color: #72D05F;
    color: #fff;
  }
 
  .progressBarP.completedStep {
    background-color: #72D05F;
    height: 3px;
  }
  
  .progressBarP.completedStep  .tickMarkStep  {
    background: #72D05F;
    color: white;
  }

  /*  */
.onBoardPrimaryDetails {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border-radius: 6px;
    padding: 1rem;
    margin-top: 2rem;
}
.onBoardPrimaryDetailsPara {
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.onBoardPrimaryDetailsLabel {
    color: var(--unnamed-color-243360);
    font: normal normal 300 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #243360 !important;
    font-size: 15px;
}
.onBoardPrimaryDetailsData {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

}
.onBoardPrimaryDetailsInputData {
    height: 39px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding-left: 10px;
}
.onBoardPrimaryDetailsInputs {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1rem;
}
.onBoardingPrimaryDetailsSpanH {
    display: flex;
    justify-content: space-between;
}
.ShippingAddressSpanP {
    font: normal normal medium 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #8C94AF;
    margin: 1;
}
.onBoardPrimaryDetailsInputsTwo {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}
.AddNewShippingAddress {
    font: normal normal medium 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #D53358;
    text-transform: capitalize;
    border: none;
    width: 13rem;
    background: transparent;
    padding-top: 1rem;
}
/* compnesation css */
/*  */
.CompensationDivHeader {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border-radius: 6px;
    padding: 1.5rem;
    margin-top: 2rem;
}
.EmploymenttypeLabel {
    font: normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360 !important;
}
.Employmenttype {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.percentageValueSpan {
    text-align: left;
    font: normal normal 15px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    display: flex;
    gap: 10px;
}
.percentageValueParaDiv {
    display: flex;
    gap: 1rem;
}
.percentageValueParaDivWithInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.percentageValuePara {
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    padding-top: 1rem;
}
.percentageValuePrimarDetails {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    border-top: 2px dashed #929EBE62;
    padding-top: 2rem;
    /* border: 2px dashed #929EBE62; */
}
.EmploymenttypeSelect {
    width: 361px;
    height: 39px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding-left: 1rem;
}

/* agreement css */
/*  */
.mailTimlineDiv {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: 1px solid #C2CBE3;
    border-radius: 6px;
    margin-top: 1rem;
}
.mailTimelneDateAndTimeDiv {
    height: 48px;
    background: #FEECEC 0% 0% no-repeat padding-box;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
.mailTimelneDocName {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
p.mailTimelneDateAndTime {
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
p.mailTimelineDescription {
    font: normal normal medium 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    padding: 4px;
}
p.mailTimelineHeading {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #243360;
    padding: 4px;

}
.AgreementDivAttachments {
    width: 580px;
    height: 85px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
span.AgreementDivAttachments {
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.AgreementDivAttachmentsP1 {
    font: normal normal 14px/22px Open Sans;
    letter-spacing: 0px;
    color: #243360;
}
.AgreementDivButtons {
    height: 32px;
    border-radius: 3px;
    width: auto;
    border: none;
    padding: 3px 12px;
    text-align: center;
    /* display: flex; */
    color: #8D95B0;
        margin-top: 1rem;
cursor: pointer;
}
.activeFilter{
    color: white;
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;

}
.AgrrementDivFilterButton {
    height: 41px;
    display: flex;
    gap: 2px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 23rem;
    justify-content: center;
    align-items: center;
}
p.AgreementDivAttachmentsP2 {
    font: normal normal normal 13px/19px Open Sans;
    letter-spacing: 0px;
    color: #495281;
}
.borderRightAttacchments::after {
    content: "|";
    border-right: 2px solid #9ea1b1;
    color: #9ea1b1;
    height: 34px;
    border-radius: 0;
    position: absolute;
    margin-left: 12rem;
    margin-bottom: 24px;
    content: "";
}


/* client Contact css */
/*  */
.ClientContactDivLast {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border-radius: 6px;
}
.buttonsCancAndNext {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #0000000A;
    border: 1px solid #DADADAB3;
    display: flex;
    gap: 1rem;
    justify-content: end;
    padding-top: 11px;
    padding-right: 10px;
    margin-top: 17px;
}
.buttonsCancAndNextCanc {
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #495281;
    text-transform: capitalize;
    border: none;
    width: auto;
    background: transparent;
    padding-top: 8px;
    cursor: pointer;
}
.buttonsCancAndNextSave {
    height: 40px;
    background: transparent linear-gradient(180deg, #F72E30 0%, #C73568 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border: none;
    width: auto;
    color: white;
    padding: 5px 14px;
    cursor: pointer;
}
label#fileUploadLastAgreement {
    margin-top: 3rem;
    margin-left: 8rem;
    margin-bottom: 3rem;
}
  